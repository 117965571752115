import $ from 'jquery'

const $doc = $(document)

export default function wysiwyg(){
  // Add wrapper to embedded iframes
  if ($('.wysiwyg-editor iframe').length) {
    $('.wysiwyg-editor iframe').each(function(e) {
      $(this).wrap('<div class="iframe-container"/>')
    })
  }
}
