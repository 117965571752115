import $ from 'jquery'

export function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function init(){
  var hasCookie = getCookie('privacy-cookies');
  if(!hasCookie) $(".cookies").slideToggle();

  $('.cookies__cta a').on('click', function() {
    $(".cookies").hide();
    setCookie('privacy-cookies', 'true', 180);
  });

  // login banner cookies
  if($(".top-banner").length){
    var hasCookie = getCookie('login-banner-cookies');
    if(!hasCookie) {
      $(".home").addClass('banner-active');
      $(".top-banner").show();
    }
  }

  $(".top-banner__close").on('click', function(){
    $(".top-banner").hide();
    $(".home").removeClass('banner-active');
    setCookie('login-banner-cookies', 'true', 1);
  })
}

export default function initCookies(){
  $(document).ready( init )
}
