import $ from 'jquery'
import openModal from '/lib/openModal'
import { updateState, loadState } from './localstorage'

const  $doc = $(document)
var    bookmarksTotal = 0;

function loadBookmarks(){
  populateModal()
  updateIconsAndBadges()
}
function populateModal(){
  const arrayOfKeys = Object.keys(loadState())
  arrayOfKeys.forEach(key => appendToPopup(key) )
  // AFTER POPULATING, UPDATE HEADER BADGE;
  bookmarksTotal = $(".popup__saved-item").length
  addNavigationBadge()
}
function saveArticle(id) {
  bookmarksTotal++
  $(`[nftId=${id}]`).find('.js-bookmark').addClass('active')
  updateState(id, false, populateArticle(id))
  appendToPopup(id)
  syncUserData(true)
}
function removeArticle(id) {
  bookmarksTotal--
  $(`[nftId=${id}]`).find('.js-bookmark').removeClass('active')
  $('.popup__content__saved').find(`.popup__saved-item[nftModalid=${id}]`).remove()
  updateState(id, true)
  syncUserData(true)
}
function syncUserData(update = false, clear = false, logout = false) {
  const state = Object.keys(loadState()).length === 0 ? '' : JSON.stringify(loadState())
  const data = {
    action: 'save_userdata',
    data: state,
    update,
    clear: clear || (!state.length && update),
    logout
  }
  const $req = $.ajax({
    type: "POST", 
    url: SITE.AJAX_URL, 
    data 
  })

  $req.done(function (res, textStatus, xhr) {
    xhr.status == 202 && updateState(false, false, res.data)
    xhr.status == 204 && handleLogout()
    loadBookmarks()  
  })
  .fail(function(e) {
    console.error('Could not save user data', e)
  });
}
function appendToPopup(id) {
  const articleObject = loadState(id)
  const articleTemplate = buildArticleHTMLtemplate(articleObject)
  !$(`.popup__saved-item[nftModalid=${id}]`).length && $('.popup__content__saved').append(articleTemplate)

}
function populateArticle(id) {
  return {
    id,
    title: $(`[nftId=${id}]`).data('title'),
    img: $(`[nftId=${id}]`).data('img'),
    url: $(`[nftId=${id}]`).data('url'),
    opensea: $(`[nftId=${id}]`).data('opensea'),
    days: $(`[nftId=${id}]`).data('days'),
    author: $(`[nftId=${id}]`).data('author'),
    purchase: $(`[nftId=${id}]`).data('purchasetext'),
  }
}
function buildArticleHTMLtemplate({id, url, img, title, author, opensea, days, purchase}) {
return `
  <div nftModalId="${id}" class="popup__saved-item">
    <a href="${url}" class="popup__saved-item__text">
      <img src="${img}" alt="Saved NFT" />
      <div><span>${title}</span> <span>${author ? `by ${author}` : ''}</span></div>
    </a>
    <div class="popup__saved-item__nft">
      <div>
        ${days ? `<span class="days-left">Ends in: ${days}</span>` : ''}
        <div>
          <a target="_blank" href="${opensea}" class="u-color-purple">${purchase ? purchase : 'Purchase on OpenSea'}</a>
        </div>
      </div>
      <div class="bookmark active js-modal-bookmark"></div>
    </div>
  </div>
`
}
function bookmarksUpdateStorage(e){
  let parent;

  if($(e.target).hasClass("single-post-bookmark")){
    // CPT single post
    parent = $(e.target).closest('.single-content')
  }
  else if($(e.target).hasClass("bookmark__anthology")){
    // post item - anthology
    parent = $(e.target).closest('.post-item')
  }
  else{
    // post item - default
    parent = $(e.target).closest('.post-item-parent')
  }
  const id = parent ? $(parent).attr('nftId') : false

  if(!$(e.target).hasClass('active')){
    // if it's not bookmarked yet
    saveArticle(id)
  }
  else{
    // if it's bookmarked already
    removeArticle(id)
  }
  addNavigationBadge()
}

function bookmarksModalRemoveSelf(e) {
  const id = $(e.target).closest('.popup__saved-item').attr('nftmodalid')
  removeArticle(id)
  addNavigationBadge()
}

function updateIconsAndBadges(){
  $("[nftId]").each(function() {
    const nftId = $(this).attr('nftId')
    const checkStorageNft = loadState(nftId)
    if(checkStorageNft) $(this).find('.js-bookmark').addClass('active')
  });
  addNavigationBadge()
}

function addNavigationBadge(){
  if(bookmarksTotal > 0) {
    $(".nav__bookmark").addClass('hasItems')
    $(".nav__bookmark span").text(bookmarksTotal)
  }
  else {
    $(".nav__bookmark").removeClass('hasItems')
    $(".nav__bookmark span").text('')
  }
}
function handleLogout() {
  updateState(false, false, {})
  location.href = '/?logged-out'
}
export default function initBookmarks(){
  $( () => { syncUserData() } )
  $doc.on('click', '.menu-item--login .js-logout', () => { syncUserData(null, null, true) })
  $doc.on('click', '.js-bookmark', bookmarksUpdateStorage )
  $doc.on('click', '.js-modal-bookmark', bookmarksModalRemoveSelf )
  $doc.on('click', '.js-header-bookmark', e => { openModal(e, [{'.mfp-bg': 'mfp-part-height'}, {'.mfp-wrap': 'mfp-part-height'}] ) })
}