import $ from 'jquery'

export default function openModal(e, active_states = []){
  const targetModal = e.target.dataset.targetModal;
  var scroll = $(window).scrollTop();
  // active_states is an array of objects: key = css selector; value = classname to toggle -- {selector: classname}
  active_states = [{html: 'modal-active'}, ...active_states]
  active_states.forEach(obj => {
    const state = Object.entries(obj)[0]
    const selector = state[0]
    const classname = state[1]
    $(selector).addClass(classname)
  })
  $.magnificPopup.open({
    items: {
      src: `#${targetModal}`,
      type: 'inline',
      verticalFit: true,
    },
    closeBtnInside: true,
    callbacks: {
      close: function() {
        $(".mfp-close").trigger('click')
      }
    }
  });
  if(scroll > 0){
    $(".mfp-bg").addClass('mfp-full-height');
    $(".mfp-wrap").addClass('mfp-full-height');
  }
  // states to remove that were added outside of this function
  let remove_active = [{html: 'takeover-active'}, ...active_states]
  $(".mfp-close").on('click', function(){
    remove_active.forEach(obj => {
      const state = Object.entries(obj)[0]
      const selector = state[0]
      const classname = state[1]
      $(selector).removeClass(classname);
    })
  });
}
