import $ from 'jquery'
const $doc = $(document)

function copyLink(event) {
  let link = $(event.currentTarget).attr('href')
  if (link.substr(0, 6) != 'mailto') {
    event.preventDefault()
    if (copyToClipboard(link)) {
      const confirm = $(event.currentTarget).data('confirm')
      $('.content-side').prepend(`<div class="tooltip">${confirm}</div>`) 
    }
  }
}

/**
 * Copy a string to clipboard
 * @param  {String} string         The string to be copied to clipboard
 * @return {Boolean}               returns a boolean correspondent to the success of the copy operation.
 * @see https://stackoverflow.com/a/53951634/938822
 */
 function copyToClipboard(string) {
  let textarea;
  let result;

  try {
    textarea = document.createElement('textarea');
    textarea.setAttribute('readonly', true);
    textarea.setAttribute('contenteditable', true);
    textarea.value = string;

    document.body.appendChild(textarea);

    textarea.select();

    const range = document.createRange();
    range.selectNodeContents(textarea);

    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);

    textarea.setSelectionRange(0, textarea.value.length);
    result = document.execCommand('copy');
  } catch (err) {
    console.error(err);
    result = null;
  } finally {
    document.body.removeChild(textarea);
  }

  // manual copy fallback using prompt
  if (!result) {
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
    result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
    if (!result) {
      return false;
    }
  }
  return true;
}

function clearCopyState(e) {
  if ( $(e.target).hasClass('js-share-publication') ) return false;
  $('.tooltip').length && $('.tooltip').remove()
}

function openShareWindow(event) {
  let link = $(event.currentTarget).attr('href')
  if (link.substr(0, 6) != 'mailto') {
    event.preventDefault()
    window.open(link, '', 'width=600, height=400')
  }
}

export default function initShareButtons(){
  $doc.on('click', '.js-share', openShareWindow )
  $doc.on('click', '.js-share-publication', copyLink )
  $doc.on('click', 'body', clearCopyState )
}
