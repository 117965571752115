export const loadState = function(id = null) {
  const serializedState = localStorage.getItem('state')
  let state = {}

  if (serializedState == null && id) {
    state = undefined
  }

  if (serializedState != null) {
    state = serializedState
  }

  if (typeof state == 'string') {
    state = JSON.parse(serializedState)
  }
  
  if (state && id) {
    return state[id]
  } else {
    return state == '{}' ? {} : state
  }
}
export const updateState = function (newItemId, shouldRemoveItem, newState) {
  if (!newItemId && !newState) return undefined

  let currentState = loadState()

  if (shouldRemoveItem && newItemId) 
    delete currentState[newItemId]
  
  if (newItemId && newState) 
    currentState[newItemId] = newState;

  if (!newItemId && newState) 
    currentState = newState

  const newSerializedState = JSON.stringify(currentState)

  localStorage.setItem('state', newSerializedState)
}