import $ from 'jquery'
import openModal from '/lib/openModal'

const $doc = $(document)
const $html = $('html')
const $mobileNav = $('.js-mobile-nav')
const $hamburger = $('.js-hamburger')
const $closeMenu = $('.js-nav-close')

function showMobileNav(event) {
  $(".mfp-close").click();
  event.preventDefault()
  $mobileNav.toggleClass('is-active')
  $hamburger.toggleClass('is-active')
  $html.toggleClass('takeover-active')

  if ($mobileNav.hasClass('is-active')) {
    $hamburger.attr('aria-expanded', 'true')
  } else {
    $hamburger.attr('aria-expanded', 'false')
  }
}

function toggleMobileDropdown(el) {
  $('.nav--takeover__box-dropdown').css('display', 'none');
  $(el).find('.nav--takeover__box-dropdown').css('display', 'flex');
}
function toggleAccountPopupView(e) {
  const view = $(e.target).data('toggle')
  $('[data-view]').removeClass('active')
  $(`[data-view=${view}]`).addClass('active')
  
  $('[data-toggle]').removeClass('active')
  $(e.target).addClass('active')
}


function categoryNav() {
  var url = window.location.href;
  $(".nav--categories a").each(function( index ) {
    var href = $(this).attr('href');
    if(url === href){
      $(this).addClass('active-nav');
    }
  });
}

function appendNavModalClasses() {
  $(".footer-open-modal a, .nav-takeover-modal a").addClass("js-open-modal");
  $(".footer-open-modal a, .nav-takeover-modal a").attr("data-target-modal", "modal-2");
  $(".menu-item--login a").addClass("js-open-login login");
  $(".menu-item--login a").attr("data-target-modal", "modal-3");
  $(".menu-item--login").append('<a href="#" class="js-logout logout">Logout</a>');
}

export default function initNav() {
  categoryNav();
  appendNavModalClasses();
  $doc.on('click', '.js-hamburger', showMobileNav)
  $doc.on('click', '.js-nav-close', showMobileNav)
  $doc.on('click', '.menu-item--login .js-open-login', e => { openModal(e, [{html: 'modal-transparent'}] ) })
  // LOGOUT EVENT HANDLER LOCATED IN ./bookmarks.js
  $doc.on('click', '.js-toggle-view', toggleAccountPopupView)
  $('.nav--takeover__box').on('click', function() {
    toggleMobileDropdown($(this));
  })
  $doc.on('keydown', function(event) {
    if ($mobileNav.hasClass('is-active') && (event.key == "Escape")) {
      $hamburger.attr('aria-expanded', 'false')
      $mobileNav.toggleClass('is-active')
      $hamburger.toggleClass('is-active')
    }
  })
  $doc.on('focusin', function(event) {
    var $target = $(event.target);
    if (!$target.closest('.js-header-wrapper').length) {
      if ($mobileNav.hasClass('is-active')) {
        $hamburger.attr('aria-expanded', 'false')
        $mobileNav.toggleClass('is-active')
        $hamburger.toggleClass('is-active')
      }
    }
  })
}
